
import React from 'react';

import {connect} from 'react-redux';



import './paginaprincipal.scss';






const Ppaginaprincipal = ({match, loftytoken, loftyuser, addLoftyUserApp, deleteLoftytokenApp, addLoftyTokenApp}) => {
  
  
  
  
  
  

  
  return (
    <div id="id659ed866b0184704f908792d" >

      <p className="" id="iinz">
        <span>Hola Este es el mundo de la programación</span>
      </p>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;



export default connect(mapStateToProps)(Ppaginaprincipal);
  